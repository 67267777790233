<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con" v-if="!$Util.isEmpty(items)">
          <complaint-list-index :items="items" />
          <div v-observe-visibility="visibilityChanged"></div>
        </div>
        <empty-index :content="'접수 내역이 없습니다.'" v-else />
        <div class="page_btn">
          <div class="btn_wrap">
            <button class="btn on" @click="fnWrite">민원 등록</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ComplaintListIndex from '@/components/views/complaint/list'
import EmptyIndex from '@/components/views/empty/full'
import { useRouter } from 'vue-router'
import { getCurrentInstance, reactive, toRefs } from 'vue'

export default {
  name: 'menu-complaint-index',
  description: '민원접수 리스트',
  components: { ComplaintListIndex, EmptyIndex },
  setup() {
    const { proxy } = getCurrentInstance()
    const router = useRouter()

    const state = reactive({
      params: {
        page: 1,
        limit: 10
      },
      items: [],
      pageInfo: []
    })

    const fnListBox = async () => {
      const res = await proxy.$ComplaintSvc.fetchUserComplaintList(state.params)
      if (res.code === 1) {
        state.items = state.items.concat(res.entity.list)
        state.pageInfo = res.entity.pageInfo
        state.params.page++
      }
    }

    const fnWrite = () => {
      router.push({ path: '/menu/complaint/write' })
    }

    const visibilityChanged = async isVisible => {
      if (isVisible) {
        if (state.pageInfo.totalPage < state.params.page) return false
        await fnListBox()
      }
    }

    fnListBox()
    return { ...toRefs(state), fnListBox, fnWrite, visibilityChanged }
  }
}
</script>

<style scoped></style>
