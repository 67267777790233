<template>
  <div class="list_page2">
    <div class="box_con">
      <div class="box_list_wrap">
        <div class="box_shadow m_8">
          <div class="box box_one">
            <div
              class="box_list_wrap"
              v-for="(item, index) in items"
              :key="index"
              @click="fnPage(item.comp.compNo)"
            >
              <div class="box_list">
                <div class="list_ttl">
                  <div class="left">
                    <div class="m_4">
                      <span class="build_name">{{ buildingName }}</span>
                    </div>
                    <p>
                      {{ item.comp.title }}
                    </p>
                    <div class="price_box">
                      <span class="price_txt">{{
                        $dayjs(item.comp.regDate).format('YYYY.MM.DD HH:mm:ss')
                      }}</span>
                    </div>
                  </div>
                  <div class="middle_box flex1none">
                    <div class="middle">
                      <p
                        class="price r_12"
                        :class="{
                          deposit: item.comp.isReply === 1,
                          f_g: item.comp.isReply === 0
                        }"
                      >
                        {{ item.comp.isReply === 0 ? '답변대기' : '답변완료' }}
                      </p>
                    </div>
                    <div class="icon"></div>
                  </div>
                </div>
              </div>
              <div class="line_grey none"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'component-complaint-list-index',
  description: '민원접수 리스트',
  props: {
    items: {
      type: [Array],
      default: () => {}
    }
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const buildingName = store.getters['user/getData'].buildingName

    const fnPage = item => {
      router.push({ path: '/menu/complaint/view', query: { compNo: item } })
    }
    return { fnPage, buildingName }
  }
}
</script>

<style scoped></style>
